import app from 'firebase/app';
import firestore from 'firebase/firestore'; // keep 
import storage from 'firebase/storage'; // keep 

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.fieldValue = app.firestore.FieldValue;
    this.db = app.firestore(); 
    this.storage = app.storage().ref();
    this.analytics = app.analytics();
  } 

  posts = () => this.db.collection('posts');
  post = uid => this.db.doc(`posts/${uid}`);

}
export default Firebase;


