import React from "react";

const Grid = ({
  amount = 200,
  position,
  containerSize,
  unitSize,
  skewContainer = "0deg",
  skewUnit = "0deg",
}) => {
  const border = {
    border: `1px solid grey`,
    borderColor: 'rgba(100,100,100,0.1)',
    boxSizing: "border-box",
  };

  const styles = {
    container: {
      pointerEvents: "none",
      width: containerSize[0],
      height: containerSize[1],
      position: "absolute",
      top: position[1],
      left: position[0],
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      transform: `skewX(${skewContainer})`,
    },
    unit: {
      width: unitSize[0],
      height: unitSize[1],
      transform: `skewX(${skewUnit})`,
      marginTop: -1,
      marginLeft: -1,
      ...border,
    },
  };

  const arr = Array.from(Array(amount).keys());

  return (
    <div style={styles.container}>
      {arr.map((el) => {
        return <div style={styles.unit}></div>;
      })}
    </div>
  );
};

export default Grid;
