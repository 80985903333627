import React, { useState, useEffect } from "react";
import Grid from "../../../misc/Grid";
import PoweredBy from "../../../misc/PoweredBy";
import { finderWidth, finderHeight } from "../../../../constants";

import { TextBlock, ChannelBlock, LinkBlock, ImageBlock } from "./Block";

const Arena = require("are.na");

export const Finder = ({ params }) => {
  const initialChannel = ["1060894"];

  const arena = new Arena();
  const [contents, setContents] = useState();
  const [stack, setStack] = useState(initialChannel);
  const [title, setTitle] = useState("loading ...");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getChannel(stack[0]);
  }, []);

  const getChannel = (channelID) => {
    setLoading(true);
    arena
      .channel(channelID)
      .get({ page: 1, per: 999 })
      .then((res) => {
        setLoading(false);
        setTitle(res.title);
        setContents(res.contents.reverse());
      })
      .catch((err) => console.log(err));
  };

  const pushChannel = (channelID) => {
    getChannel(channelID);
    setStack([...stack, channelID]);
  };

  const popChannel = () => {
    if (stack.length > 1) {
      let newStack = stack;
      newStack.pop();
      getChannel(newStack[newStack.length - 1]);
      setStack(newStack);
    }
  };

  const renderBlock = (content) => {
    const contentClass = content.class;
    console.log(content);

    if (contentClass === "Text") {
      const text = content.content;
      return <TextBlock key={content.id} text={text} />;
    } else if(contentClass==="Attachment"){
      const url = content.attachment.url;
      const image = content.image.display.url;
      const title = content.title;

      return (
        <LinkBlock
          title={title}
          isAttach
          link={url}
          imageUrl={image}
          key={content.id}
        />
      );
    } else if (contentClass === "Channel") {
      const channelID = content.id;
      const title = content.title;
      return (
        <ChannelBlock
          key={content.id}
          title={title}
          channelID={channelID}
          pushChannel={pushChannel}
        />
      );
    } else if (contentClass === "Link") {
      const imageUrl = content.image.display.url;
      const link = content.source.url;
      const title = content.title;
      return (
        <LinkBlock
          title={title}
          link={link}
          imageUrl={imageUrl}
          key={content.id}
        />
      );
    } else if (contentClass === "Image") {
      const imageUrl = content.image.display.url;
      return <ImageBlock imageUrl={imageUrl} key={content.id} />;
    }
  };

  return (
    <div style={styles.container}>
      <Grid
        amount={250}
        position={[0, 0]}
        containerSize={[2000, 2000]}
        unitSize={[150, 150]}
        skewUnit={"-1.5deg"}
      ></Grid>
      <div style={styles.viewer}>
        {!loading &&
          contents &&
          contents.map((content) => renderBlock(content))}
        {loading && <LoadingGear />}
        <div style={{ zIndex: 5 }}>
          <PoweredBy />
        </div>
      </div>
      <Header popChannel={popChannel} title={title} stack={stack} />
    </div>
  );
};

const Header = ({ popChannel, title, stack }) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const buttonStyles = buttonHovered
    ? { ...styles.buttonWrap, ...styles.button_hover }
    : { ...styles.buttonWrap, ...styles.button };

  const canPop = stack.length > 1;

  return (
    <div style={styles.header}>
      <div
        onClick={() => popChannel()}
        style={buttonStyles}
        onMouseEnter={canPop ? () => setButtonHovered(true) : null}
        onMouseLeave={() => setButtonHovered(false)}
      >
        {stack.length > 1 && <div style={styles.arrow}>pop</div>}
      </div>
      <div style={styles.second}>{title}</div>
    </div>
  );
};

const LoadingGear = () => {
  let loadingStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: "#aaa",
  };

  return <div style={loadingStyles}>loading ...</div>;
};

const styles = {
  container: {
    backgroundColor: "black",
    width: finderWidth,
    height: finderHeight,
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
    position: "relative",
  },
  header: {
    position: "absolute",
    top: 0,
    width: "60%",
    marginTop: -2,
    height: 40,
    border: "0.1px solid #aaa",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 5,
    marginRight: 100,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    boxSizing: "border-box",
    backdropFilter: "blur(3px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    zIndex: 3,
  },
  second: {
    fontFamily: "lunchtype",
    marginLeft: "auto",
    color: "#aaa",
    paddingLeft: 10,
  },
  channel: {
    color: "#aaa",
    paddingLeft: 10,
    fontFamily: "lunchtype",
  },
  buttonWrap: {
    fontFamily: "lunchtype",
    cursor: "pointer",
    fontWeight: "bold",
    width: 100,
    height: "50%",
    margin: 2,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(-3px), saturate(3)",
    transition: "all 1s ease",
  },
  button: {
    border: "1px solid #aaa",
    borderRadius: 1,
    color: "#aaa",
  },
  button_hover: {
    // borderTopLeftRadius: 50,
    // borderBottomLeftRadius: 50,
    border: "1px solid #eee",
    color: "#eee",
  },
  viewer: {
    width: "100%",
    paddingTop: 100,
    boxSizing: "border-box",
    paddingBottom: 100,
    height: "100%",
    overflow: "scroll",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
};
