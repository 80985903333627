import React, { useState} from "react";

const PoweredBy = () => {
  const [hovered, setHovered] = useState(false);
  return (
    <a
      href={"https://www.are.na/"}
      target={"_blank"}
      style={hovered ? styles.poweredByHovered : styles.poweredBy}
      onMouseEnter={() => setHovered(true)}
    >
      powered by **
    </a>
  );
};

const styles = {
  poweredBy: {
    backgroundColor: "black",
    fontFamily: "lunchtype",
    position: "absolute",
    boxSizing: "border-box",
    transition: "all 1s ease-out",
    fontSize: 10,
    bottom: 0,
    right: 0,
    padding: 10,
    borderRadius: 2,
    width: 130,
    height: 40,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    filter: "blur(2px)",
    textDecoration: 'none',
  },
  poweredByHovered: {
    // backgroundColor: "white",
    color: "#aaa",
    fontFamily: "lunchtype",
    position: "absolute",
    transition: "all 1s ease-out",
    boxSizing: "border-box",
    fontSize: 10,
    bottom: 10,
    right: 10,
    padding: 10,
    width: 100,
    height: 25,
    borderRadius: 100,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #aaa",
    transform: "rotate3d(1, 0, 0, 360deg)",
    textDecoration: 'none',
  },
};

export default PoweredBy;
