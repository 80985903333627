import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Rnd } from "react-rnd";
import {
  videoWidthDesktop,
  videoHtoWRation,
  cscriptWidthDesktop,
  cscriptWidthMobile,
  videoWidthMobile,
  cscriptHeightDesktop,
  cscriptHeightMobile,
  finderWidth,
  finderHeight,
} from "../../constants";
import { isBrowser } from "react-device-detect";

const Window = ({
  focused,
  title,
  content,
  focusWindow,
  deleteWindow,
  type,
}) => {
  const [isFocused, setIsFocused] = useState(focused);

  const _focusWindow = (title) => {
    setIsFocused(true);
    focusWindow(title);
  };
  useEffect(() => {
    setIsFocused(focused);
  }, [focused]);

  const getWidth = () => {
    if (type === "video") {
      return isBrowser ? videoWidthDesktop : videoWidthMobile;
    } else if (type === "cscript") {
      return isBrowser ? cscriptWidthDesktop : cscriptWidthMobile;
    } else if (type === "network") {
      return isBrowser ? 850 : 250;
    } else if (type === "finder") {
      return isBrowser ? finderWidth : finderWidth;
    } else {
      return 300;
    }
  };

  const getHeight = () => {
    if (type === "video") {
      return (
        (isBrowser ? videoWidthDesktop : videoWidthMobile) * videoHtoWRation
      );
    } else if (type === "cscript") {
      return isBrowser ? cscriptHeightDesktop : cscriptHeightMobile;
    } else if (type === "network") {
      return isBrowser ? 250 : 200;
    } else if (type === "finder") {
      return isBrowser ? finderHeight : finderHeight;
    } else {
      return 300;
    }
  };

  const width = getWidth();
  const height = getHeight();
  const centerPosVideoX = window.innerWidth / 2 - width / 2;
  const centerPosVideoY = window.innerHeight / 2 - height / 2;

  const getDefault = () => {
    return {
      x: centerPosVideoX,
      y: centerPosVideoY,
      width: width,
    };
  };

  return (
    <Rnd default={getDefault()}>
      <div className={styles.window} style={{ zIndex: 1 }}>
        <div className={styles.top}>
          <div
            className={isFocused ? styles.box_1 : styles.transparentBox}
            onClick={() => deleteWindow(title)}
          >
            <div
              style={
                isFocused
                  ? {
                      width: "3px",
                      height: "3px",
                      backgroundColor: "black",
                      borderRadius: "1px",
                    }
                  : {}
              }
            ></div>
          </div>
        </div>
        <div
          style={{ boxSizing: "border-box" }}
          onClick={() => _focusWindow(title)}
        >
          {content}
        </div>
      </div>
    </Rnd>
  );
};

export default Window;
