export const col_babyBlue = "rgb(142,181,249)";
export const col_white = "rgb(255,255,255)";
export const col_ubitualGrey = "rgb(145, 145, 145)";
export const col_hotRed = "rgb(255, 60, 0)";
export const col_hotRed_fade = "rgb(255, 60, 0, 0.1)";
export const col_finder_01 = "rgb(234,234,235)";
export const col_finder_02 = "rgb(250,250,250)";

// Charscript
export const cscriptWidthDesktop = 900
export const cscriptWidthMobile = 400

export const cscriptHeightDesktop = 500
export const cscriptHeightMobile = 100

// Video
export const videoWidthDesktop = 1000
export const videoHeightDesktop = 562.5
export const videoWidthMobile = 500


// apps
export const dialogueWidth  = 1000
export const dialogueHeight  = 625

export const finderWidth = 1000
export const finderHeight = 625

export const videoHtoWRation = "0.60"
