import React from "react";
import styles from "./index.module.scss";
import {
  aboutTxtWindow,
  descriptionTxtWindow,
  eyes,
  fullface,
  dialogue,
  network,
  finder,
  creditsCharscript,
} from "../windows/apps/WindowCollection";

const font = "ibmiso8";

const Navigation = ({ addWindow, setFocused }) => {
  const addAndFocusWindow = (window) => {
    addWindow(window);
    setFocused(window.title);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(aboutTxtWindow)}
      >
        <Icon src={"icons/text.png"} label={"about.char"} font={font}></Icon>
      </div>

      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(creditsCharscript)}
      >
        <Icon src={"icons/text.png"} label={"credits.char"} font={font}></Icon>
      </div>

      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(descriptionTxtWindow)}
      >
        <Icon src={"icons/text.png"} label={"guide.char"} font={font}></Icon>
      </div>

      <div className={styles.element} onClick={() => addAndFocusWindow(finder)}>
        <Icon src={"icons/folder.png"} label={"finder"} font={font}></Icon>
      </div>

      <div className={styles.element} onClick={() => addAndFocusWindow(eyes)}>
        <Icon src={"icons/disk_2.png"} label={"eyes.act"} font={font}></Icon>
      </div>

      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(fullface)}
      >
        <Icon
          src={"icons/disk_2.png"}
          label={"fullface.act"}
          font={font}
        ></Icon>
      </div>

      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(dialogue)}
      >
        <Icon src={"icons/dialogue.png"} label={"dialogue"} font={font}></Icon>
      </div>
      <div
        className={styles.element}
        onClick={() => addAndFocusWindow(network)}
      >
        <Icon src={"icons/network.png"} label={"network"} font={font}></Icon>
      </div>
    </div>
  );
};

const Icon = (props) => {
  return (
    // <Draggable>
    <div className={styles.icon}>
      <img className={styles.image} src={props.src} alt="a" />
      <div
        className={styles.label}
        style={{
          fontFamily: props.font,
          fontSize: "0.7em",
          fontWeight: "bold",
        }}
      >
        {props.label}
      </div>
    </div>
    // </Draggable>
  );
};

export default Navigation;
