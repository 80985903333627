export default function sketch(p) {
    let cam, pan;
      let delta = 0.5;
      let backColor= p.color(0, 0, 0);
    //    let color = p.color(142, 181, 249) ;
       let color = p.color(212, 232, 255) ;
  
    p.setup = () => {
      p.createCanvas(850,550, p.WEBGL);
      cam = p.createCamera();
      cam.tilt(-0.6);
    };
  
    p.draw = () => {
  
      if (p.frameCount % 100 === 0) {
          delta += 0.01;
          // pan += 0.001
        }
  
      //   cam.move(delta, delta/10, 0);
        cam.pan(0.0001);
        cam.tilt(0.0001);
  
      p.background(backColor);
      let radius = p.width * 3.0;
      
      //drag to move the world.
      p.orbitControl();
  
      p.normalMaterial();
      p.translate(0, 100, -100);
      for (let i = 0; i <= 10; i++) {
        for (let j = 0; j <= 10; j++) {
          p.noFill();
          p.stroke(color);
          p.push();
          let a = (j / 12) * p.PI;
          let b = (i / 12) * p.PI;
          p.translate(
            p.sin(2 * a) * radius * p.sin(b),
            (p.cos(b) * radius) / 2,
            p.cos(2 * a) * radius * p.sin(b)
          );
          
          p.sphere(1800);
  
          p.pop();
        }
      }
    };
  }
  