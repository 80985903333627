import React, { useState, useEffect } from "react";

export const TextBlock = ({ text }) => {
  const style = {
    ...blockStyles.baseContainer,
    ...blockStyles.container,
    borderRadius: 0,
  };

  return (
    <div style={style}>
      <div
        style={{ height: "90%" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};

export const ChannelBlock = ({ title, channelID, pushChannel }) => {
  return (
    <Transitionable isChannel>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => pushChannel(channelID)}
      >
        [ ] {title}{" "}
      </div>
    </Transitionable>
  );
};

export const LinkBlock = ({ title, link, imageUrl, isAttach }) => {
  return (
    <Transitionable isAttach={isAttach}>
      <a
        href={link}
        target={"_blank"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={blockStyles.image} src={imageUrl} alt={title}></img>
      </a>
    </Transitionable>
  );
};

export const ImageBlock = ({ imageUrl }) => {
  const style = {
    ...blockStyles.baseContainer,
    ...blockStyles.container,
  };
  return (
    <div style={style}>
      <img style={blockStyles.image} src={imageUrl} alt={"add some"}></img>
    </div>
  );
};

const Transitionable = ({ children, isChannel, isAttach }) => {
  const [hovered, setHovered] = useState(false);

  const transStyles = hovered
    ? { ...blockStyles.baseContainer, ...blockStyles.hoveredContainer }
    : {
        ...blockStyles.baseContainer,
        ...blockStyles.container,
        border: "1px solid #aaa",
      };

  const tagStyles = hovered
    ? { ...blockStyles.baseTag, ...blockStyles.tag_hover }
    : { ...blockStyles.baseTag, ...blockStyles.tag };

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={transStyles}
    >
      {!isChannel && (
        <div style={tagStyles}>{isAttach? "attachment": 'link'} {String.fromCharCode(8599)} </div>
      )}
      {children}
    </div>
  );
};

const blockStyles = {
  baseContainer: {
    width: 400,
    boxSizing: "border-box",
    height: 400,
    padding: 10,
    margin: 40,
    overflowY: "scroll",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.5s ease",
    zIndex: 1,
  },
  container: {
    color: "#aaa",
    border: "1px solid black",
    backgroundColor: "transparent",
    borderRadius: 5,
  },
  hoveredContainer: {
    color: "#aaa",
    border: "1px solid #aaa",
    backgroundColor: "rgb(100,100,100,0.3)",
    borderRadius: 15,
  },
  image: {
    width: "90%",
  },
  baseTag: {
    fontFamily: "lunchtype",
    fontSize: '',
    position: "absolute",
    zIndex: 2,
    height: 25,
    paddingLeft:10,
    paddingRight: 10,
    textAlign: "center",
    transition: "all 0.5s ease",
    pointerEvents: "none",
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tag: {
    color: "transparent",
    backdropFilter: "invert(0)",
  },
  tag_hover: {
    backdropFilter: "invert(.3)",
    color: "white",
  },
};
