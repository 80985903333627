import React, { useState } from "react";
import styles from "./index.module.scss";
import Window from "./window";
import { defaultWindows, allWindows } from "./apps/WindowCollection";
import Navigation from "../navigation";
import Footer from "../footer";
import { VideoWrapper } from "./apps/WindowCollection";
import { isBrowser } from "react-device-detect";
import WindowMobile from "./windowMobile";

const Windows = () => {
  const [windows, setWindows] = useState(defaultWindows);
  const [focused, setFocused] = useState(
    defaultWindows[defaultWindows.length - 1].title
  );

  const focusWindow = (title) => {
    const windowToFocus = windows.find((window) => {
      return window.title === title;
    });
    setFocused(title);
    const newArray = windows.filter((window) => {
      return window.title !== title;
    });
    setFocused(title);
    setWindows([...newArray, windowToFocus]);
  };

  const addWindow = (window) => {
    const windowExist = windows.find((_window) => {
      return _window.title === window.title;
    });

    if (!windowExist) {
      setWindows([...windows, window]);
    } else {
      focusWindow(windowExist.title);
    }
  };

  const deleteWindow = (title) => {
    const newArray = windows.filter((window) => {
      return window.title !== title;
    });
    setWindows(newArray);
  };

  const browserRender = () => {
    return (
      windows.length > 0 &&
      windows.map((element, i) => {
        return (
          <Window
            type={element.type}
            key={element.title}
            focused={element.title === focused}
            focusWindow={focusWindow}
            deleteWindow={deleteWindow}
            position={element.position}
            title={element.title}
            content={
              element.type === "video" ? (
                <VideoWrapper videoUrl={element.content} />
              ) : (
                element.content
              )
            }
          />
        );
      })
    );
  };

  const mobileRender = () => {
    return <WindowMobile focused={true} windows={allWindows}></WindowMobile>;
  };

  return (
    <div className={styles.container}>
      <Navigation addWindow={addWindow} setFocused={setFocused} />
      {isBrowser ? browserRender() : mobileRender()}

      {isBrowser ? (
        <Footer focusWindow={focusWindow} tabs={windows} focused={focused} />
      ) : (
        <Footer tabs={[{ title: "on your phone" }]} />
      )}
    </div>
  );
};

export default Windows;
