import firebase from "@firebase/app";
import "@firebase/firestore";
import React, { useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import styles from "./index.module.scss";
import { compose } from "recompose";
import { withFirebase } from "../../../../Firebase";
import shortid from "shortid";
import { storage } from "firebase";
import {
  dialogueWidth,
  dialogueHeight,
  col_ubitualGrey,
  col_hotRed,
  col_white,
} from "../../../../constants";

const maxTextChars = 512;
const maxUsernameChars = 24;

const Dialogue = (props) => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState();
  const [fileBase64, setfileBase64] = useState();
  const [posts, setPosts] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isSubmitHovered, setIsSubmitHovered] = useState();

  const onDrop = (_file) => {
    const file = _file[0];
    setFile(file);
    getBase64(file, (result) => {
      setfileBase64(result);
    });
  };

  const getBase64 = (file, cb) => {
    if (file) {
      var fileTypes = ["jpg", "jpeg", "png", "gif"]; //acceptable file types
      var extension = file.name.split(".").pop().toLowerCase(); //file extension from input file
      var isSuccess = fileTypes.indexOf(extension) > -1;

      if (isSuccess) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          cb(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }
  };

  const clearUpload = () => {
    setfileBase64(null);
    setFile(null);
  };

  const clearAllfields = () => {
    setUsername("");
    setText("");
    setFile("");
    setfileBase64(null);
    setFile(null);
  };

  useEffect(() => {
    let unsubscribe = props.firebase
      .posts()
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        let posts = [];
        snapshot.forEach((doc) => posts.push({ ...doc.data(), uid: doc.id }));
        setPosts(posts);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (posts) {
      setImageUrl(posts[0].imageUrl);
    }
  }, [posts]);

  const submit = () => {
    setLoading(true);
    if (file) {
      const id = shortid.generate();
      let task = props.firebase.storage.child(`/images/${id}`).put(file);

      task.on(
        storage.TaskEvent.STATE_CHANGED,
        (snap) => {
          console.log("loading");
        },
        (err) => {
          console.log(err);
        },
        () => {
          task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            createPost(downloadURL);
            console.log("File available at", downloadURL);
          });
        }
      );
    } else {
      createPost();
    }
  };

  const createPost = (uploadUrl) => {
    let _username = username ? username : "";
    let _text = text ? text : "";
    let _imageUrl = uploadUrl ? uploadUrl : "";

    let post = {
      imageUrl: _imageUrl,
      text: _text,
      username: _username,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    props.firebase
      .posts()
      .add(post)
      .then(() => {
        console.log("success");
        setLoading(false);
        clearAllfields();
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  const onSubmitHover = () => {
    setIsSubmitHovered(true);
  };

  return (
    <div style={s.container}>
      <div style={s.blogContainer}>
        <div style={s.left}>
          <div style={s.imageContainer}>
            {imageUrl && (
              <img alt={"message eufekt"} style={s.image} src={imageUrl}></img>
            )}
            {!imageUrl && (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  border: "1px solid white",
                }}
              ></div>
            )}
          </div>
        </div>
        <div style={s.posts}>
          {posts &&
            posts.map((post) => (
              <div
                style={s.oneBlog}
                onMouseOver={() => {
                  setImageUrl(post.imageUrl);
                  console.log(post);
                }}
              >
                <span style={s.oneBlogTitle}>{post.username}</span>
                {post.imageUrl && imageUrl === post.imageUrl ? <span> __</span> : null}
                <div
                  style={{
                    ...s.blogPost,
                  }}
                >
                  {post.text}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div style={s.editorContainer}>
        <div style={s.areaContainer}>
          <div style={s.uploadContainer}>
            <div style={s.titleArea}>
              <div>Dialogue</div>
            </div>
            <div style={s.uploadArea}>
              <div
                style={{
                  ...s.cancelButton,
                  color: fileBase64 ? "black" : "transparent",
                }}
                onClick={() => clearUpload()}
              >
                x
              </div>
              <div style={s.previewContainer}>
                {fileBase64 ? (
                  <img
                    className={styles.rotating}
                    style={s.imagePreview}
                    src={fileBase64}
                    alt={"contact admin"}
                  />
                ) : null}
              </div>
              <ImageUploader
                className={styles.dialogue}
                withIcon={false}
                buttonText="+ add image"
                withLabel={false}
                fileContainerStyle={{
                  padding: 0,
                  border: "none",
                  boxShadow: "none",
                  width: "80%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                buttonStyles={{
                  pointerEvents: fileBase64 ? "none" : true,
                  fontFamily: "powerline",
                  color: fileBase64 ? "transparent" : col_ubitualGrey,
                  backgroundColor: "transparent",
                  fontSize: "15px",
                  border: "none",
                  zIndex: 1,
                }}
                errorStyle={{ paddingLeft: "10px", paddingRight: "10px" }}
                onChange={onDrop}
                singleImage={true}
                imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                maxFileSize={5097152}
              />
            </div>
          </div>
          <div style={s.textContainer}>
            <input
              placeholder={"auteur"}
              style={s.username}
              maxlength={maxUsernameChars}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <textarea
              placeholder={"message"}
              style={s.textarea}
              maxLength={maxTextChars}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div style={s.alert}>
              <div style={s.warning}>NOTE</div>
              <div style={s.explain}>
                Soumettez ici vos commentaires, impressions, perceptions,
                interrogations par rapport à l’expérience que vous avez vécue.
                Vous pouvez même y insérer une image qui traduit votre pensée.
                **NOTE : tous commentaires transmis seront publiés dans un «
                catalogue » virtuel accessible au grand public.
              </div>
            </div>
          </div>
        </div>
        <div
          style={s.bottom}
          onMouseEnter={() => onSubmitHover(true)}
          onMouseLeave={() => setIsSubmitHovered(false)}
        >
          <button
            style={{
              ...s.buttonContainer,
              backgroundColor: loading
                ? "white"
                : isSubmitHovered
                ? col_hotRed
                : "white",
            }}
            onClick={username && text ? submit : false}
          >
            {loading ? (
              <div style={{ color: "black" }}>LOADING</div>
            ) : isSubmitHovered ? (
              username && text ? (
                <div>
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    CLICK
                  </span>{" "}
                  TO SUBMIT POST AS
                  <span style={s.usernameSubmit}>
                    {" "}
                    {` ${username}` ? username : " ________"}
                  </span>
                </div>
              ) : (
                "les champs auteur et message sont obligatoires"
              )
            ) : (
              <div>READY?</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const greenborder = {
  borderStyle: "solid",
  borderColor: col_white,
  borderWidth: "1px",
  borderRadius: "7px",
};

const clear = {
  border: "none",
  overflow: "auto",
  outline: "none",
  webkitBoxShadow: "none",
  mozBoxShadow: "none",
  boxShadow: "none",
  resize: "none",
};

const s = {
  container: {
    width: dialogueWidth,
    height: dialogueHeight,
    backgroundColor: "black",
    display: "flex",
    flexDirection: "column",
  },
  blogContainer: {
    fontFamily: "helvetica",
    display: "flex",
    flexDirection: "row",
    height: "60%",
    ...greenborder,
    backgroundColor: "white",
    margin: "3px",
    marginBottom: 0,
    paddingBottom: "2px",
    paddingRight: "30px",
    position: "relative",
    zIndex: 6,
    boxSizing: "border-box",
  },
  left: {
    width: "42%",

    margin: "10px",
    marginRight: 20,
    borderStyle: "solid",
    borderRadius: 10,
  },
  posts: {
    width: "58%",
    overflow: "scroll",
  },
  oneBlog: {
    paddingTop: "30px",
    paddingBottom: "30px",
    fontSize: "16px",
  },
  oneBlogTitle: {
    fontWeight: "bold",
    fontSize: "25px",
  },
  blogPost: {
    zIndex: 4,
    transition: "all 1s ease",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    zIndex: 4,
    backgroundColor: "black",
  },
  image: {
    width: "80%",
    transition: "all 0.5s ease",
  },
  editorContainer: {
    fontFamily: "compaq",
    height: "40%",
    backgroundColor: col_ubitualGrey,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    ...greenborder,
    margin: "3px",
    marginTop: "3px",
    padding: "10px",
    paddingBottom: "5px",
  },
  areaContainer: {
    height: "90%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderWidth: "1px",
    borderRadius: "25px",
  },
  uploadContainer: {
    width: "20%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    marginBottom: "10px",
    paggingBottom: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  titleArea: {
    height: "25%",
    padding: "5px",
    fontSize: "45px",
    paddingBottom: "10px",
    fontFamily: "redcoat",
    color: col_ubitualGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "borderBox",
    flexShrink: 0,
  },
  uploadArea: {
    height: "60%",
    width: "100%",
    position: "relative",
    borderRadius: "10px",
    display: "flex",
    fontSize: "30px",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "borderBox",
    flexShrink: 0,
  },
  previewContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 3,
    marginRight: "6px",
    marginTop: "3px",
    cursor: "pointer",
    fontFamily: "powerline",
    color: col_ubitualGrey,
  },
  imagePreview: {
    zIndex: 2,
    width: "25%",
    top: 0,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "inset 0 0 10px #000000",
  },
  statsArea: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    paddingTop: "6px",
    paddingBottom: "6px",
    boxSizing: "borderBox",
  },
  stats: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "borderBox",
    paddingLeft: "2px",
    paddingRight: "2px",
    justifyContent: "space-between",
    alignItems: "center",
    color: col_white,
    fontSize: "14px",
    height: "30%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    height: "100%",
  },
  username: {
    ...clear,
    fontFamily: "powerline",
    fontSize: "15px",

    borderRadius: "10px",
    boxSizing: "borderBox",
    backgroundColor: col_white,
    height: "15%",
    paddingLeft: "10px",
    paddingRight: "5px",
  },
  textarea: {
    ...clear,
    fontFamily: "powerline",
    fontWidth: "bold",
    fontSize: "15px",

    borderRadius: "10px",
    boxSizing: "borderBox",
    marginTop: "10px",
    backgroundColor: col_white,
    height: "60%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  alert: {
    height: "25%",
    marginTop: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    flexDirection: "row",
    border: "1px solid white",
    borderRadius: "10px",
  },
  warning: {
    color: col_hotRed,
    fontSize: "20px",
    paddingLeft: "10px",
    textAlign: "justify",
  },
  explain: {
    width: "85%",
    fontSize: "15px",
    lineHeight: "0.9",
    paddingLeft: "10px",
    color: col_white,
  },
  bottom: {
    height: "10%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "2px",
    paddingRight: "-2px",
  },

  buttonContainer: {
    width: "100%",
    fontSize: "15px",
    justifyContent: "center",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "10px",
    backgroundColor: col_white,
    display: "flex",
    alignItems: "center",
    pointer: "point",
  },
  submitHovered: {
    backgroundColor: "cyan",
  },
  usernameSubmit: {
    fontStyle: "italic",
  },
};

export default compose(withFirebase(Dialogue));
