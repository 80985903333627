import React, { useState } from "react";

export const Charscript = ({ author, date, textEn, textFr }) => {
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [textColor, setTextColor] = useState("white");
  const [change, setChange] = useState("white");
  const [language, setLanguage] = useState("fr");

  const changeColor = () => {
    if (backgroundColor === "black") {
      setBackgroundColor("white");
      setTextColor("black");
      setChange("black");
    } else {
      setBackgroundColor("black");
      setTextColor("white");
      setChange("white");
    }
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        fontSize: 15,
        lineHeight: 1.1,
        color: "grey",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",

      }}
    >
      <div
        style={{
          color: textColor,
          padding: "10px",
          fontFamily: "powerline",
          justifyContent: 'left',
          overflow: "scroll",
        }}
      >
        {language === "fr" ? textFr : textEn}
      </div>
      <div
        style={{
          height: "20px",
          bottom: 10,
          margin: "5px",
          padding: "5px",
          color: "grey",
          boxSizing: "border-box",
          border: "1px",
          borderStyle: "solid",
          borderColor: "grey",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>by {author}</div>

          <div style={{ paddingLeft: 20 }}>{date}</div>

          <div style={{ paddingLeft: 20 }} onClick={() => setLanguage("fr")}>
            FR
          </div>
          <div style={{ paddingLeft: 10 }} onClick={() => setLanguage("en")}>
            EN
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "10px",
              boxSizing: "border-box",
              width: "50px",
              backgroundColor: change,
              borderWidth: 1,
              borderColor: "white",
              borderStyle: "solid",
              marginLeft: "5px",
            }}
            onClick={() => changeColor()}
          ></div>
        </div>
      </div>
    </div>
  );
};
