    // in the reducer you will need to keep track of all the windows.
    // create a window
    // delete a window
    import { createStore } from 'redux';

    
    // initial store state
    const initialState = {
      windows: [], // each window has id and title given at creation.
      activeWindowId: null // this is the active window id
    };
    
    const reducer = (state = initialState, action) => {
      if (action.type === 'CREATE_WINDOW') {
        console.log(action.windowIdentity)
        return {
          ...state,
          windows:[...state.windows, action.windowIdentity],
          
        };
      }
      
      if (action.type === 'FOCUS_WINDOW') {
        const title =  action.title;
        const toFocus = state.windows.find((window)=>{
          return window.title === title
        })
        const windows = state.windows.filter((window)=>{
          return window.title !== title;
        })
        
        return {
          ...state,
          windows: [...windows, toFocus]
        };
      }

      if(action.type === 'DELETE_WINDOW') {
        const title =  action.title;
        
        const windows = state.windows.filter((window)=>{
          return window.title !== title;
        });

        console.log(windows);

        return {
          ...state,
          windows: [...windows]
        };
      }

      return state;

    };
    
    const store = createStore(reducer);
    
    export default store;