import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Rnd } from "react-rnd";
import {
  videoWidthDesktop,
  videoHtoWRation,
  cscriptWidthDesktop,
  cscriptWidthMobile,
  videoWidthMobile,
  cscriptHeightDesktop,
  cscriptHeightMobile,
  col_ubitualGrey,
} from "../../constants";
import { isBrowser } from "react-device-detect";
import { VideoWrapper } from "./apps/WindowCollection";

const WindowMobile = ({
  windows,
  focused,
  title,
  content,
  focusWindow,
  deleteWindow,
  type,
  size,
}) => {
  const [isFocused, setIsFocused] = useState(focused);

  const _focusWindow = (title) => {
    setIsFocused(true);
    focusWindow(title);
  };
  useEffect(() => {
    setIsFocused(focused);
  }, [focused]);

  const getWidth = () => {
    if (type === "video") {
      return isBrowser ? videoWidthDesktop : videoWidthMobile;
    } else if (type === "cscript") {
      return isBrowser ? cscriptWidthDesktop : cscriptWidthMobile;
    }
  };

  const getHeight = () => {
    if (type === "video") {
      return (
        (isBrowser ? videoWidthDesktop : videoWidthMobile) * videoHtoWRation
      );
    } else if (type === "cscript") {
      return isBrowser ? cscriptHeightDesktop : cscriptHeightMobile;
    }
  };

  const width = getWidth();
  const height = getHeight();
  const centerPosVideoX = window.innerWidth / 2 - width / 2;
  const centerPosVideoY = window.innerHeight / 2 - height / 2;

  const getDefault = () => {
    if (type !== "app") {
      return {
        x: centerPosVideoX,
        y: centerPosVideoY,
        width: width,
      };
    }
  };

  return (
    <div className={styles.windowMobile}>
      <div className={styles.top}>
        <div className={isFocused ? styles.box_1 : styles.transparentBox}>
          <div
            style={
              isFocused
                ? {
                    width: "3px",
                    height: "3px",
                    backgroundColor: "black",
                    borderRadius: "1px",
                  }
                : {}
            }
          ></div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'black',
          boxSizing: "border-box",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {windows.map((window) => {
          if (window.type == "video") {
            return (
              <div className={styles.mobileSeparator}>
                <VideoWrapper videoUrl={window.content} />
              </div>
            );
          } else {
            return <div className={styles.mobileSeparator}>{window.content}</div>;
          }
        })}
      </div>
    </div>
  );
};

export default WindowMobile;
