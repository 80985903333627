import React from "react";
import styles from "./index.module.scss";

const Footer = ({tabs, focusWindow, focused}) =>  {
   tabs = tabs.sort(function(a, b) {
    var textA = a.title.toUpperCase();
    var textB = b.title.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
    return (
      <div className={styles.container}>
        <div className={styles.bar}>
          <div className={styles.tab_start}></div>
          {tabs.length> 0 && tabs.map((tab) => (
            <div key={tab.title} className={focused === tab.title ? [styles.tab, styles.focused].join(' '):styles.tab} onClick={()=>focusWindow(tab.title)}>
              <div className={styles.title}>{tab.title}</div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Footer;
