import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Charscript } from "./Charscript";
import Dialogue from "./Dialogue/Dialogue";
import P5Wrapper from "react-p5-wrapper";
import {
  col_hotRed,
  col_babyBlue,
  col_hotRed_fade,
  videoWidthDesktop,
  videoHeightDesktop,
  col_ubitualGrey,
} from "../../../constants";
import sketch from "../../sketches/network";
import { Finder } from "./Finder/Finder";

const textDescriptifFr = (
  <div style={{ textAlign: "left" }}>
    « Bonjour, je suis A. »
    <br />
    « Je vous invite, par le biais de cette plateforme, à plonger votre esprit
    dans un état méditatif. »
    <br />
    « Pour ce faire, je vous propose de méditer d’une manière qui consiste à
    déposer votre regard dans le mien de la façon et la durée qui vous
    conviendra. » <br />
    <br />
    1. « Adoptez une position qui vous est confortable. »
    <br />
    2. « Tournez votre regard sur l'une des vidéos proposées en respirant
    calmement. »
    <br />
    3. « Détendez tous les muscles de votre corps. »
    <br />
    4. « Fermez ensuite les yeux et maintenez votre attention sur un sujet de
    méditation que ce soit ce que vous avez observé, votre respiration ou un son
    ambiant » <br />
    5. « Maintenez les yeux fermés aussi longtemps que vous souhaitez mediter. »
    <br />
    6. « Quand le moment vous semblera bon, ouvrez les yeux doucement. » <br />
  </div>
);

const textDescriptifEn = (
  <div style={{ textAlign: "left" }}>
    "Hi, I'm A."
    <br />
    "I invite you, through this platform, to immerse your mind into a meditative
    state."
    <br />
    "To help you in this process, I suggest that you meditate in a way that
    consists of placing your gaze into mine in the manner and duration
    that suits you most."
    <br />
    <br />
    1. "Adopt a position that is comfortable for you."
    <br />
    2. "Turn your gaze to one of the videos presented while breathing calmly."
    <br />
    3. "Relax all the muscles in your body."
    <br />
    4. "Then close your eyes and keep your attention on a subject of meditation
    whether it is what you have observed, your breathing or an ambient sound."
    <br />
    5. "Keep your eyes closed as long as you wish to meditate."
    <br />
    6. "When the time is right for you, open your eyes slowly."
    <br />
  </div>
);

const textAboutEn = (
  <div style={{ textAlign: "justify" }}>
    Anagogie (2020-today) is an interactive web platform that thrives on the
    desire to establish and expose the relationship between the Internet user,
    meditative practice and cyberspace. In response to the fast-paced digital
    culture, this web space challenges how the user interacts with digital
    technologies and the online world. The platform proposes a conscious
    approach that is more anchored in the present space-time. The different
    interfaces, such as video windows, the ressource folder and the dialogue box
    suggest taking a sensitive and informed look at the pace of the web
    consumption.
    <br />
    <br />
    Through a meditative experience, Anagogie gives the user the possibility to
    perform an introspection and to break down the barriers that separate him
    from the web space. The user thus becomes an integral part of the project. Cybernauts
    also have the opportunity to share their findings and perceptions through a
    connected and secure network. A sharing that offers to cultivate our
    individual and collective understanding of the cyberspace.
    <br />
  </div>
);

const textAboutFr = (
  <div style={{ textAlign: "justify" }}>
    Anagogie (2020-aujourd’hui) est une plateforme web interactive qui carbure
    au désir d’établir et d’exposer la relation entre l’internaute, la pratique
    méditative et le cyberespace. En réaction à la cadence effrénée qu’emprunte
    la culture du numérique, cet espace web permet de remettre en question la
    manière dont l'utilisateur/trice interagit avec les technologies
    numériques et le cybermonde. La plateforme propose une approche consciente
    qui s’ancre davantage dans l’espace-temps présent. Les différentes
    interfaces, telles que les fenêtres vidéos et les boîtes de dialogues,
    suggèrent d’adopter un regard sensible et averti face au rythme de
    consommation du web.
    <br />
    <br />
    Par le biais d’une expérience méditative, Anagogie octroie au cybernaute
    la possibilité d’effectuer une introspection et de briser les barrières qui
    le/la sépare de l’espace web. Il/elle devient ainsi partie intégrante du
    projet. L’internaute a également la possibilité de partager ses conclusions,
    ses perceptions au travers d’un réseau connecté et sécurisé. Un partage qui
    offre de cultiver notre compréhension individuelle et collective du
    cyberespace.
    <br />
  </div>
);

const credits = (
  <div style={{ width: 400, fontSize: 10 }}>
    <div style={{ paddingBottom: 15 }}>
      <a
        href={"http://www.iconian.com/r.html"}
        style={{
          color: "inherit",
          textDecoration: "none",
          fontFamily: "redcoat",
          fontSize: 30,
          marginTop: 4,
        }}
        target={"_blank"}
      >
        REDCOAT
        {/* {String.fromCharCode(8599)} */}
      </a>
      {"  "}
      by the web
    </div>
    <div style={{ paddingBottom: 15 }}>
      <a
        href={"https://usemodify.com/fonts/lunchtype/"}
        style={{
          color: "inherit",
          fontFamily: "lunchtype",
          textDecoration: "none",
          fontSize: 30,
        }}
        target={"_blank"}
      >
        lunchtype
        {/* {String.fromCharCode(8599)} */}
      </a>
      {"  "}
      by Stefan Wetterstrand
    </div>
    <div>
      <a
        href={"https://usemodify.com/fonts/lunchtype/"}
        style={{
          color: "inherit",
          textDecoration: "none",
          fontFamily: "powerline",
          fontSize: 30,
        }}
        target={"_blank"}
      >
        ProFont
        {/* {String.fromCharCode(8599)} */}
      </a>{" "}
      by powerline
    </div>
    <br />
    contact - anagogie.dialogue[at]gmail[dot]com
    <br />
    Nichita Hariton - Concepteur & Designer web & Vidéaste - @eufekt
    <br />
    Amélie Labonté - Performeuse et Direction Artistique - @amelabonte
    <br />
  </div>
);

const textWarningEn = (
  <div style={{ textAlign: "left" }}>
    <span style={{ color: col_hotRed, backgroundColor: col_hotRed_fade }}>
      (MOBILE USERS){" "}
    </span>{" "}
    for the best experience visit this website on a personal computer.
  </div>
);

const textWarningFr = (
  <div style={{ textAlign: "left", backgroundColor: col_hotRed_fade }}>
    <span style={{ color: col_hotRed }}>(MOBILE USERS) </span>
    pour la meilleure expérience, visitez ce site Web sur un ordinateur
    personnel.
  </div>
);

/********************************************************************************************************************/

export const VideoWrapper = ({ videoUrl }) => {
  const [ready, setReady] = useState(false);
  const wrapperStyle = {
    // width: "100%",
    // height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "-4px",
  };

  return (
    <div style={wrapperStyle}>
      {
        <ReactPlayer
          width={videoWidthDesktop}
          height={videoHeightDesktop}
          url={videoUrl}
          playing
          playsinline
          loop
          volume={0}
          muted
          onReady={() => {
            setReady(true);
          }}
        />
      }
      <div
        style={{
          width: videoWidthDesktop,
          height: videoHeightDesktop,
          backgroundColor: "rgb(180,198,218)",
          position: "absolute",
          opacity: !ready ? 1 : 0,
          transition: "all 0.5s ease",
          fontFamily: "lunchtype",
          fontSize: "2vw",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        loading
      </div>
    </div>
  );
};

export const network = {
  type: "network",
  position: { x: 100, y: 0 },
  title: "network",
  content: (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <P5Wrapper sketch={sketch} />
    </div>
  ),
};

export const finder = {
  type: "finder",
  title: "finder",
  content: <Finder />,
};

export const dialogue = {
  type: "app",
  title: "Dialogue",
  content: <Dialogue />,
};

export const descriptionTxtWindow = {
  type: "cscript",
  title: "guide.char",
  content: (
    <Charscript
      author={"A&N"}
      date={"1/5/2021"}
      textEn={textDescriptifEn}
      textFr={textDescriptifFr}
    />
  ),
};

export const aboutTxtWindow = {
  type: "cscript",
  title: "about.char",
  content: (
    <Charscript
      author={"A&N"}
      date={"1/5/2021"}
      textEn={textAboutEn}
      textFr={textAboutFr}
    />
  ),
};

export const mobileWarning = {
  type: "cscript",
  title: "warning",
  content: (
    <Charscript
      author={"A&N"}
      date={"1/5/2021"}
      textEn={textWarningEn}
      textFr={textWarningFr}
    />
  ),
};

export const creditsCharscript = {
  type: "cscript",
  title: "credits.char",
  content: (
    <Charscript
      author={"source direct"}
      date={"1/5/2021"}
      textEn={credits}
      textFr={credits}
    />
  ),
};

export const eyes = {
  type: "video",
  title: "eyes.act",
  content:
    "https://firebasestorage.googleapis.com/v0/b/amelie-f2d23.appspot.com/o/eyes_1.mp4?alt=media&token=ad5049c9-4b6c-49f7-aa52-c064100168ba",
};

export const fullface = {
  type: "video",
  title: "fullface.act",
  content:
    "https://firebasestorage.googleapis.com/v0/b/amelie-f2d23.appspot.com/o/fullface_1.mp4?alt=media&token=50849dfb-2abd-4c65-99a4-bfc3eb340650",
};

export const defaultWindows = [
  // aboutTxtWindow,
  descriptionTxtWindow,
  eyes,
  // creditsCharscript,
  // finder,
  // network,
  // fullface,
  // dialogue,
].reverse();

export const allWindows = [
  descriptionTxtWindow,
  fullface,
  aboutTxtWindow,
  eyes,
  mobileWarning,
  // network,
].reverse();
