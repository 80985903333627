import React,  {Component} from 'react';
import styles from './index.module.scss';
import Windows from '../windows/index';

class Main extends Component {
    render() {
        return (
            <div className={styles.container}>
                <Windows></Windows>
            </div>
        );
}
}

export default Main;
